import { useState } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
function Addform2a({setCheckedRadios_a}){
    library.add( faTimes );

    const [radioOne, setradioOne] = useState('');
    const [radioTwo, setradioTwo] = useState('');
    const [radioThree, setradioThree] = useState('');
    const [radioFour, setradioFour] = useState('');
    const [radioFive, setradioFive] = useState('');
    const [countryOfBirth, setCountryOfBirth] = useState('');
    const [occupation, setOccupation] = useState('');

    const [errorPopup, setErrorPopup] = useState(false);
    
    async function handelRadioButton(event, name){
        if(name === 1){           
            setradioOne(event.target.value)
        }
        if(name === 2){            
            setradioTwo(event.target.value)
        }
        if(name === 3){
            setradioThree(event.target.value)
        }
        if(name === 4){
            setradioFour(event.target.value)
        }

        if(name === 5){
            setradioFive(event.target.value)
        }
    }

    handelRadioButton().then(
        process()
    )

    function process(){
        if(
            radioOne === 'yes' && 
            radioTwo === 'yes' && 
            radioFive === 'no' &&
            radioThree === 'no' && 
            radioFour === 'no' 
            
        ){
            setCheckedRadios_a(true);
        }else{
            setCheckedRadios_a(false);
            // setErrorPopup(true);
        }
    }

    const [deposit, setDeposit] = useState('');

    const  onlyText = event => {
        const result = event.target.value.replace(/[^a-z\u0590-\u05fe]/gi, '');
        setDeposit(result);
    }

    function openErrorPop(){
        setErrorPopup(true);
    }
    
    function closeSubPop(){
        setErrorPopup(false)
    }

    const changeCountryOfBirth = event => {
        const text = event.target.value.replace(/[^a-z\u0590-\u05fe]/gi, '');
        setCountryOfBirth(text);
    }


    const changeOccupation = event => {
        const text = event.target.value.replace(/[^a-z\u0590-\u05fe]/gi, '');
        setOccupation(text)
    }

    
    return(
        <>
            <section className="completeFormBlock">
                <div className="formInner">
                    <div className="countBar">
                        <button></button>
                        <button className="active"></button>
                        <button></button>
                        <button></button>
                    </div>
                    <h2>הכר את הלקוח שותף/ה</h2>

                    <div className="mainForm">

                        <div className="formFildSingles smallMargin">
                            <input type="text" value={countryOfBirth} onChange={changeCountryOfBirth} className="formFildInpt" placeholder="ארץ לידה" />
                        </div>

                        <div className="completeRadioWrap">
                            <p>תושב ישראל</p>
                            <div className="radioBox">
                                <label><input type="radio" value='yes' name="radio6a" onChange={(e) => handelRadioButton(e, 1)} /><span><b className="mark"></b></span> <h5>כן</h5></label>
                                <label><input type="radio" value='no'  name="radio6a" onChange={(e) => handelRadioButton(e,1)} onClick={openErrorPop} /><span><b className="mark"></b></span> <h5>לא</h5></label>
                            </div>
                        </div>

                        <div className="completeRadioWrap">
                            <p>אזרחות ישראלית</p>
                            <div className="radioBox">
                                <label><input type="radio" value='yes' name="radio7a" onChange={(e) => handelRadioButton(e, 2)} /><span><b className="mark"></b></span> <h5>כן</h5></label>
                                <label><input type="radio" value='no' name="radio7a" onChange={(e) => handelRadioButton(e, 2)} onClick={openErrorPop}  /><span><b className="mark"></b></span> <h5>לא</h5></label>
                            </div>
                        </div>

                        <div className="completeRadioWrap">
                            <p>אזרחות ארה״ב</p>
                            <div className="radioBox">
                                <label><input type="radio" value='yes' name="radio10a" onChange={(e) => handelRadioButton(e, 5)} onClick={openErrorPop}  /><span><b className="mark"></b></span> <h5>כן</h5></label>
                                <label><input type="radio" value='no' name="radio10a" onChange={(e) => handelRadioButton(e, 5)} /><span><b className="mark"></b></span> <h5>לא</h5></label>
                            </div>
                        </div>

                        <div className="completeRadioWrap">
                            <p>תושב ארה״ב לצורך מס</p>
                            <div className="radioBox">
                                <label><input type="radio" value='yes' name="radio8a" onChange={(e) => handelRadioButton(e, 3)} onClick={openErrorPop} /><span><b className="mark"></b></span> <h5>כן</h5></label>
                                <label><input type="radio" value='no' name="radio8a" onChange={(e) => handelRadioButton(e, 3)} /><span><b className="mark"></b></span> <h5>לא</h5></label>
                            </div>
                        </div>

                        <div className="completeRadioWrap">
                            <p>תושב מדינה זרה לצורך מס</p>
                            <div className="radioBox">
                                <label><input type="radio" value='yes' name="radio9a" onChange={(e) => handelRadioButton(e, 4)} onClick={openErrorPop} /><span><b className="mark"></b></span> <h5>כן</h5></label>
                                <label><input type="radio" value='no' name="radio9a" onChange={(e) => handelRadioButton(e, 4)}  /><span><b className="mark"></b></span> <h5>לא</h5></label>
                            </div>
                        </div>                        

                        <div className="formFildSingles">
                            <input type="text" className="formFildInpt onlyText"  value={deposit} onChange={onlyText} placeholder="עיר" />
                        </div>

                        <div className="smallHalfWrapper">
                            <div className="shBlocks">
                                <span>רחוב</span>
                                <input type="text" placeholder="נא לבחור מהרשימה" className="formFildInpt" />
                            </div>
                            <div className="shBlocks small">
                                <span>מספר</span>
                                <input type="number" className="formFildInpt" />
                            </div>
                        </div>

                        <div className="smallHalfWrapper">
                            <div className="shBlocks">
                                <span>דירה</span>
                                <input type="text" placeholder="נא לבחור מהרשימה" className="formFildInpt" />
                            </div>
                            <div className="shBlocks small">
                                <span>מיקוד</span>
                                <input type="text" className="formFildInpt" />
                            </div>
                        </div>                    

                        <div className="formFildSingles">
                            <select className="formFildInpt">
                                <option>מצב תעסוקתי</option>
                            </select>
                            <input type="text" value={occupation} onChange={changeOccupation} className="formFildInpt" placeholder="עיסוק" />
                            <input type="number" className="formFildInpt" placeholder="סך ההכנסות שלי" />
                            <input type="number" className="formFildInpt" placeholder="סך ההתחייבויות שלי" />
                            <input type="number" className="formFildInpt" placeholder="היקף הנכסים הכולל" />
                        </div>     

                        <div className="completeRadioWrap">
                            <p>האם הינך איש ציבור זר או מקומי</p>
                            <div className="radioBox">
                                <label><input type="radio" value='yes' name="public" /><span><b className="mark"></b></span> <h5>כן</h5></label>
                                <label><input type="radio" value='no' name="public" /><span><b className="mark"></b></span> <h5>לא</h5></label>
                            </div>
                        </div> 

                        <div className="completeRadioWrap">
                            <p>האם הנהנה בחשבון הינו איש ציבור זר או מקומי</p>
                            <div className="radioBox">
                                <label><input type="radio" value='yes' name="beneficiary" /><span><b className="mark"></b></span> <h5>כן</h5></label>
                                <label><input type="radio" value='no' name="beneficiary" /><span><b className="mark"></b></span> <h5>לא</h5></label>
                            </div>
                        </div>                 

                        <div className="formFildSingles">
                            <input type="text" className="formFildInpt onlyText" value={deposit} onChange={onlyText} placeholder="מקור הכספים שיופקדו בחשבון" />
                            <select className="formFildInpt">
                                <option>מטרת פתיחת החשבון המנהל</option>
                            </select>
                            <select className="formFildInpt">
                                <option>צפי תדירות הפקדות בחשבון</option>
                            </select>
                            <select className="formFildInpt">
                                <option>צפי תדירות משיכות בחשבון</option>
                            </select>
                            <select className="formFildInpt">
                                <option>מצב משפחתי הדגמה פתוחה</option>
                                <option value="">רווק</option>
                                <option value="">גרוש</option>
                                <option value="">נשוי</option>
                            </select>
                        </div> 
                        
                        
                    </div>
                </div>
            </section>

            

                {   
                    errorPopup ?
                    <section className="ErrorPopUpWrap">
                        <div className="errorPopmain">
                            <span className="closePop" onClick={closeSubPop}><FontAwesomeIcon icon='fa-times' /></span>
                            <span className="erroricon"><FontAwesomeIcon icon='fa-times' /></span>
                            <h4>אופס! <br /> כותרת שגיאה מדוע</h4>
                            <p>הסבר מדוע לא ניתן להמשיך אפשר 2 שורות</p>
                            <button className="subBttn" onClick={closeSubPop}>חזרה לשינוי רמת סיכון</button>
                        </div>
                    </section>:null
                }

        </>
    )
}
export default Addform2a;