import Header from "../components/header";
import { PieChart } from 'react-minimal-pie-chart';
import ReactSlider from "react-slider";
import { Link } from "react-router-dom";

import riskIcon from "../images/riskIcon.svg";
import { useState } from "react";

function InvestmentPortfolio(){
    const steps = ['נמוכה מאד' , 'נמוכה' , 'בינונית' , 'בינונית גבוהה' , 'גבוהה' , 'גבוהה מאד'];
    const [stepText, setStepText] = useState(steps[0]);
    const [moreInfoPop, setMoreInfoPop] = useState(false);

    function changeFunction(e){
        setStepText(steps[e])
    }

    function openInfoPop() {
        setMoreInfoPop(true)
    }

    function closeInfoPop() {
        setMoreInfoPop(false)
    }

    return(
        <>
            <Header></Header>
            <section className="investmentTotalWrap">
                <div className="ipContainer">
                    <div className="ipInner">
                        <h1>הרכב תיק ההשקעות שלך</h1>
                        <div className="depositAmmount">
                            <h2>
                                <small>מגדל</small>
                                25,000
                            </h2>
                            <p>סכום הפקדה ראשוני</p>
                        </div>

                        <div className="pieChart">
                            <PieChart
                                animation
                                animationDuration={500}
                                animationEasing="ease-out"
                                center={[50, 50]}
                                data={[
                                    { title: 'One', value: 16, color: 'rgba(80, 100, 222, 0.74)' },
                                    { title: 'Two', value: 60, color: '#FF8B60' },
                                    { title: 'Three', value: 50, color: 'rgba(55, 227, 196, 0.41)' },
                                    { title: 'Four', value: 33.33, color: '#39CEF3' },
                                    { title: 'Five', value: 30, color: '#FFD583' },
                                ]}
                                labelPosition={50}
                                lengthAngle={360}
                                lineWidth={45}
                                paddingAngle={3}
                                radius={50}
                                startAngle={220}
                                viewBoxSize={[100, 100]}
                            />
                            <div className="label_one">
                                <h4>מבטחים
                                    <small>מניות בארץ</small>
                                </h4>
                            </div>
                            <div className="label_two">
                                <h4>מבטחים
                                    <small>מניות בחו״ל</small>
                                </h4>
                            </div>
                            <div className="label_three">
                                <h4>מבטחים
                                    <small>אג״ח מדינה 25,050</small>
                                </h4>
                            </div>
                            <div className="label_four">
                                <h4>מבטחים
                                    <small>אג״ח 25,050</small>
                                </h4>
                            </div>
                        </div>

                        <div className="ramgeSliderWrap">
                            <h2>לקוח שונא סיכון</h2>                            
                            <div className="rSlid">
                                <ReactSlider
                                    className="horizontal-slider"
                                    marks
                                    markClassName="example-mark"
                                    min={0}
                                    max={5}
                                    thumbClassName="example-thumb"
                                    trackClassName="example-track"
                                    renderThumb={(props) => <div {...props}><span className="thumbnail">{stepText}</span></div>}
                                    // value={2}
                                    onChange={changeFunction}
                                    ariaLabelledby={steps}   
                                    ariaLabel={steps}                 
                                />
                                <div className="arealabel">
                                    {Object.values(steps).map((step, key) => {
                                        return (
                                            <p key={key}>{step}</p>
                                        );
                                    })}
                                </div>
                                <button className="riskLevelLebel" onClick={openInfoPop}>ספרו לי עוד על רמת הסיכון</button>
                            </div>
                        </div>
                            {moreInfoPop ?
                                <section className="moreInfoPopWrap">
                                    <div className="popupContain">
                                        <h4>ספרו לי עוד</h4>
                                        <p> 
                                            <b>הנושא העיקרי של המידע</b>
                                            כאן יהיה מלל של הערה ספרו לי עוד
                                            עוד מלל שיכול למלא את השורה, היא ממשיכה להוסיף הערה ארוכה יותר שיכולה להוריד שורה נוספת למטה.
                                        </p>
                                    </div>
                                    <div className="closePopOverlay" onClick={closeInfoPop}></div>
                                </section>:null
                            }


                        <div className="riskLevelCard">
                            <img src={riskIcon} alt="" />
                            <h4>דרגה סיכון המתאימה ללקוח ״שונא סיכון״</h4>
                            <p>כאן יהיה מלל של הערה ספרו לי עודכאן יהיה מלל של הערה ספרו לי עודכאן יהיה מלל של הערה ספרו לי עוד</p>
                        </div>

                        <div className="confirmationWrap">
                            <p>האם האלוקציה מקובלת עליך?</p>
                            <div className="radioBox">
                                <label><input type="radio" value='yes' name="radio" /><span><b className="mark"></b></span> <h5>כן</h5></label>
                                <label><input type="radio" value='no' name="radio" /><span><b className="mark"></b></span> <h5>לא</h5></label>
                            </div>
                        </div>

                        <div className="subBttns">
                            <Link to="/add-member" className="subBttn">המשך לשלב הבא</Link>
                            <Link to="/risk-level" className="backbttn">חזור</Link>
                        </div>

                    </div>
                </div>
            </section>

            
        </>
    )
}
export default InvestmentPortfolio;