import { library } from "@fortawesome/fontawesome-svg-core";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
function SubmitPage(){
    library.add( faCheck );
    return(
        <>
            <section className="completeFormBlock">
                <div className="formInner">
                    <div className="countBar">
                        <button className="active"></button>
                        <button></button>
                        <button></button>
                        <button></button>
                    </div>
                    <div className="checkMarkWrap">
                        <span className="checkMark"><FontAwesomeIcon icon='fa-check' /></span>
                    </div>
                    <h2>מעולה</h2>
                    <div className="successPageText">
                        <p>השאלון נקלט בהצלחה.</p>
                        <p>והנה פה יש מקום לעוד 8 שורות השאלון נקלט בהצלחה. והנה פה יש מקום והנה פה יש מקוםלעוד 8 שורות השאלון נקלט בהצלחה. והנה פה יש מקום לעוד 8 שורות השאלון נקלט בהצלחה. והנה פה יש מקום והנה פה יש מקוםלעוד והנה פה יש מקוםלעוד 8 שורות השאלון נקלט בהצלחה.והנה פה יש מקום לעוד 8 שורות השאלון נקלט בהצלחה. והנה פה יש מקום והנה פה יש מקוםלעוד 8 שורות השאלון נקלט בהצלחה. </p>
                    </div>
                </div>
            </section>
        </>
    )
}
export default SubmitPage;