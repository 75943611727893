import coloredLogo from'../images/coloredLogo.png';
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
function Login(){

    const [phone, setPhone] = useState('');
    const [userId, setUserId] = useState('');

    const [formFealdPhone, setFormFealdPhone] = useState('loginFeald');
    const [formFealdUserId, setFormFealdUserId] = useState('loginFeald');

    const navigate = useNavigate();

    function login(){
        if(phone === '') { 
            setFormFealdPhone('loginFeald invalid')
        }
        else {
            setFormFealdPhone('loginFeald')
        }
        if(userId === '') { 
            setFormFealdUserId('loginFeald invalid')
        }
        else {
            setFormFealdUserId('loginFeald')
        }

        if(phone === '') { 
            console.warn('both invalid');
        }
        else if(userId === '') { 
            console.warn('both invalid');
        }
        else {
            console.warn('both valid');
            navigate('/enter-code');
        }
    }

    function typing(){
        if(phone !== '') { 
            setFormFealdPhone('loginFeald')
        }  
        if(userId !=='') { 
            setFormFealdUserId('loginFeald')
        }
    }
    return(
        <>  
            <section className="loginWrapper">
                <div className="loginBlockMain">
                    <span className="logo"><img src={coloredLogo} alt="" /></span>
                    <h1>עוד רגע נוכל להתחיל
                        <small>אנא הכניסו את הפרטים שלכם כדי לקבל קוד מאובטח</small>
                    </h1>
                    <div className="mainForm">
                        <input type="text" className={formFealdPhone} placeholder="טלפון" value={phone} onChange={(e)=>setPhone(e.target.value)} onKeyUp={typing} />
                        <input type="text" className={formFealdUserId} placeholder="ת.ז." value={userId} onChange={(e)=>setUserId(e.target.value)} onKeyUp={typing} />
                        <button className="subBttn" onClick={login}>שלחו לי קוד</button>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Login;