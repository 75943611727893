import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from "@fortawesome/fontawesome-svg-core";
import { useState } from "react";
function Addform1({setChecked, setShowTwo_a}){
    library.add( faCheck );

    const [spouceInfo, setSpouceInfo] = useState(false);
    const [id, setId] = useState('');
    const [phone, setPhone] = useState('');
    const [radioSix, setradioSix] = useState('');

    function haveSpouce() {
        setSpouceInfo(true)
    }
    function dontHaveSpouce() {
        setSpouceInfo(false)
    }
    
    function handelCheckBox(event){
        if (event.target.checked) {
            setChecked(true)
        }
        else {
            setChecked(false)
        }
    }

    const [firstName, setfirstName] = useState('');
    const [lastName, setlastName] = useState('');

    const [spouceFirstName, setspouceFirstName] = useState('');
    const [spouceLastName, setspouceLastName] = useState('');

    const  nameFirst = event => {
        const result = event.target.value.replace(/[^a-z\u0590-\u05fe]/gi, '');
        setfirstName(result);
    }

    const  nameLast = event => {
        const result = event.target.value.replace(/[^a-z\u0590-\u05fe]/gi, '');
        setlastName(result);
    }

    const  spouceFameFirst = event => {
        const result = event.target.value.replace(/[^a-z\u0590-\u05fe]/gi, '');
        setspouceFirstName(result);
    }

    const  spouceNameLast = event => {
        const result = event.target.value.replace(/[^a-z\u0590-\u05fe]/gi, '');
        setspouceLastName(result);
    }

    const changeID = event => {
        const value = event.target.value.replace(/\D/g, "");
        setId(value)
    }

    const changePhone = event => {
        const value = event.target.value.replace(/\D/g, "");
        setPhone(value)
    }
    

    async function formFieldTwo_a(event, name){
        if(name === 6){           
            setradioSix(event.target.value)
        }
    }
    formFieldTwo_a().then(
        showFormTwo_a()
    )
    function showFormTwo_a(){
        if(radioSix === 'yes'){
            setShowTwo_a(true);
        }
        else{
            setShowTwo_a(false);
        }
    }


    function changeRadio(){
        ///
    }
    return(
        <>
            <section className="completeFormBlock">
                <div className="formInner">
                    <div className="countBar">
                        <button></button>
                        <button></button>
                        <button></button>
                        <button className="active"></button>
                    </div>
                    <h2>פרטים אישיים</h2>

                    <div className="mainForm">
                        <div className="formFildDouble">
                            <input type="text" className="formFildInpt onlyText"  value={firstName} onChange={nameFirst} placeholder="שם פרטי" />
                            <input type="text" className="formFildInpt onlyText"  value={lastName} onChange={nameLast} placeholder="שם משפחה" />
                            <input type="text" value={id} onChange={changeID} className="formFildInpt" placeholder="ת.ז" />
                            <input type="text" value={phone} onChange={changePhone} className="formFildInpt" placeholder="טלפון" />
                        </div>

                        <div className="formFildSingles">
                            <input type="email" className="formFildInpt" placeholder="מייל" />
                            <input type="text" onFocus={(e) => e.target.type = 'date'} onBlur={(e) => e.target.type = 'text'} className="formFildInpt dateType" placeholder="תאריך לידה" />
                            <select className="formFildInpt">
                                <option>מצב משפחתי</option>
                                <option>Married</option>
                                <option>Unmarried</option>
                            </select>
                        </div>

                        <div className="completeRadioWrap">
                            <p>אני</p>
                            <div className="radioBox">
                                <label><input type="radio" value='yes' name="radio1" onChange={changeRadio} /><span><b className="mark"></b></span> <h5>זכר</h5></label>
                                <label><input type="radio" value='no' name="radio1" onChange={changeRadio} /><span><b className="mark"></b></span> <h5>נקבה</h5></label>
                            </div>
                        </div>

                        <div className="completeRadioWrap">
                            <p>האם קיימים שותפים לחשבון הבנק שלך?</p>
                            <div className="radioBox">
                                <label><input type="radio" value='yes' name="radio2" onChange={changeRadio} /><span><b className="mark"></b></span> <h5>כן</h5></label>
                                <label><input type="radio" value='no' name="radio2" onChange={changeRadio} /><span><b className="mark"></b></span> <h5>לא</h5></label>
                            </div>
                        </div>

                        <div className="completeRadioWrap">
                            <p>האם תרצה להוסיף שותף לחשבון ההשקעות שלך?</p>
                            <div className="radioBox">
                                <label><input type="radio" value='yes' name="radio3" onChange={changeRadio} /><span><b className="mark"></b></span> <h5>כן</h5></label>
                                <label><input type="radio" value='no' name="radio3" onChange={changeRadio} /><span><b className="mark"></b></span> <h5>לא</h5></label>
                            </div>
                        </div>

                        <div className="completeRadioWrap">
                            <p>האם השותף הוא בן/בת זוג שלך?</p>
                            <div className="radioBox">
                                <label><input type="radio" value='yes' name="radio4" onClick={haveSpouce} onChange={(e) => formFieldTwo_a(e, 6)} /><span><b className="mark"></b></span> <h5>כן</h5></label>
                                <label><input type="radio" value='no' name="radio4" onClick={dontHaveSpouce} onChange={(e) => formFieldTwo_a(e, 6)} /><span><b className="mark"></b></span> <h5>לא</h5></label>
                            </div>
                        </div>

                        {
                            spouceInfo ?
                            <div className="spouceInfo">
                                <h2>פרטי בן/בת הזוג</h2>
                                
                                <div className="formFildDouble">
                                    <input type="text" className="formFildInpt onlyText"  value={spouceFirstName} onChange={spouceFameFirst} placeholder="שם פרטי" />
                                    <input type="text" className="formFildInpt onlyText"  value={spouceLastName} onChange={spouceNameLast} placeholder="שם משפחה" />
                                    <input type="text" className="formFildInpt" placeholder="ת.ז" pattern = "[0-9]"/>
                                    <input type="text" className="formFildInpt" placeholder="טלפון" pattern = "[0-9]"/>
                                </div>

                                <div className="formFildSingles">
                                    <input type="email" className="formFildInpt" placeholder="מייל" />
                                    <input type="text" onFocus={(e) => e.target.type = 'date'} onBlur={(e) => e.target.type = 'text'} className="formFildInpt dateType" placeholder="תאריך לידה" />
                                </div>

                                <div className="completeRadioWrap">
                                    <p>מין</p>
                                    <div className="radioBox">
                                        <label><input type="radio" value='yes' name="radio5" onClick={changeRadio} /><span><b className="mark"></b></span> <h5>זכר</h5></label>
                                        <label><input type="radio" value='no' name="radio5" onClick={changeRadio} /><span><b className="mark"></b></span> <h5>נקבה</h5></label>
                                    </div>
                                </div>

                            </div>:null
                        }
                        

                        <div className='confirmCheckBoxWrap'>
                            <label>
                                <input type="checkbox" className="chackBox" onChange={handelCheckBox} />
                                <span><FontAwesomeIcon icon="fa-check" /></span>
                                <p>אני מאשר/ת בזאת כי אני ראשי לענות בשם כל השותפים בחשבון תיק השקעות זה</p>
                            </label>
                        </div>
                        
                    </div>
                </div>
            </section>
        </>
    )
}
export default Addform1;