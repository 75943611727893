import coloredLogo from'../images/coloredLogo.png';
import React, { useState } from 'react';
import OtpInput from 'react-otp-input';
import { useNavigate, Link } from 'react-router-dom';
function Otp(){
    const [otpBox, setOtpBox] = useState('otpBox');
    const [otp, setOtp] = useState('');
    const navigate = useNavigate();
    function submitCode(){    
        if(otp === '') { 
            setOtpBox('otpBox invalid');
        }
        else {
            navigate('/welcome');
        }
    }
    return(
        <>
            <section className="loginWrapper">
                <div className="loginBlockMain">
                    <span className="logo"><img src={coloredLogo} alt="" /></span>
                    <h1>הכניסו קוד
                        <small>נשלח אליכם קוד עם 5 ספרות</small>
                    </h1>
                    <div className="mainForm withOtp">
                        <div className={otpBox}>
                            <h4>קוד כניסה</h4>
                            <OtpInput
                                value={otp}
                                onChange={setOtp}
                                numInputs={6}
                                renderInput={(props) => <input {...props} />}
                                containerStyle={'mainOtpBox'}
                                isInputNum={true}
                            />
                            <button className="subBttn" onClick={submitCode}>כניסה</button>
                            <p>לא קיבלתי את הקוד. <Link>שלח קוד חדש</Link> </p>
                        </div>
                        
                    </div>
                </div>
            </section>
        </>
    )
}

export default Otp;