import Header from "../components/header";
import editIcon from '../images/editIcon.svg'
import { Link } from "react-router-dom";
function Dashboard(){
    return(
        <>
            <Header></Header>
            <section className="dashboardWrap">
                <div className="dbContainer">
                    <div className="dbinner">
                        <picture><img src={editIcon} alt="" /></picture>
                        <h1>שלום לך
                            <br />
                            <small>הגעת לשאלון הדיגיטלי של Folloapp, כאן נעזור לך ב...
                            תעבור שלבים פירוט השלבים</small>
                        </h1>
                        <h3>הטופס הדיגיטלי מאפשר לך להגיש</h3>
                        <h3>הטופס הדיגיטלי מאפשר לך להגיש</h3>
                        <h3>באופן מהיר ונוח.</h3>    
                        <p>ליכמ ךינפלש ספוטה .בכרומו ישיא אוה ךילהתה םיישיא םיעוריאל תוסחייתמ ןקלחו תובר תולאשונילא רבעויש עדימהש לככ לבא ,תרבעש םיבכרומו ךלש השקבה תא קודבל לכונ ךכ ,רתוי טרופמ היהי.תוריהמבו תוליעיב לכ תא אורקל ,השקבה יולימב ץמאמ עיקשהל בושח ריכהל ונל עייסיש דועית וא ךמסמ לכ ףרצלו םיטרפה ךל רוזעלו ךתוא ועית וא ךמסמ לכ ףרצלו םיטרפהועית וא ךמסמ לכ ףרצלו םיטרפהועית וא ךמסמ לכ ףרצלו.</p>                    
                        <Link to="/risk-level" className="blueBttnStyle">כניסה למילוי השאלון</Link>
                        <Link className="linkStyle">חזור</Link>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Dashboard;