import riskIcon from "../images/riskIcon.svg";
import bell from '../images/bell.svg';
import Header from "../components/header";

import { Link } from "react-router-dom";
import ReactSlider from "react-slider";
import { useState } from "react";
import popIcon from "../images/popIcon.svg";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NumericFormat } from 'react-number-format';
function RiskLevel(){
    library.add( faTimes );
    const steps = ['נמוכה מאד' , 'נמוכה' , 'בינונית' , 'בינונית גבוהה' , 'גבוהה' , 'גבוהה מאד'];
    const [riskLevelPop, setRiskLevelPop] = useState(false);
    const [stepText, setStepText] = useState(steps[0]);
    const [sliderValue, setSliderValue] = useState(150000);
    const [sliderVal, setSliderVal]     = useState('150,000')

    function slideChangeFunction(e){
        setSliderValue(e)
        let newValue=e.toString().replace(/,/g, "");
        let abc = newValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        setSliderVal(abc);
    }
    function openSlidPop(){
        setRiskLevelPop(true)
    }
    function closePop(){
        setRiskLevelPop(false)
    }
    function changeFunction(e){
        setStepText(steps[e])
    }

    function handleSetSLiderValue(e){
        let val = e.target.value;
        let val2 = val.replace(/\D/g, "");
        let newValue=val2.replace(/,/g, "");
        setSliderValue(newValue)
        let abc = newValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        setSliderVal(abc);
    }



    return(
        <>
            <Header></Header>
            <section className="completeRiskPageWrap">
                <div className="completeRiskPageContainer">
                    <div className="rpinner">
                        
                        <div className="rpTopBox">
                            <picture><img src={riskIcon} alt="" /></picture>
                            <h2>רמת הסיכון שלך נמוכה</h2>
                            <p>הסבר כאן תיאור רמת הסיכון
                                <br /> כל הסבר שאפשר לכתוב כאן
                                <br /> הסבר מדוע רמת הסיכון שלך נמוכה
                            </p>
                        </div>

                        <div className="noticeWrap">
                            <img src={bell} alt="" />
                            <p>לקוח יקר, לשינוי רמת הסיכון <Link onClick={openSlidPop}>לחץ כאן</Link></p>
                        </div>

                        <div className="sliderCompleteWrap">
                            <h1>בחר סכום השקעה
                                <small>טקסט עזר דוגמה להנחיה למשתמש</small>
                            </h1>
                            <div className="reactSlider">
                                <ReactSlider
                                    className="horizontal-slider"
                                    min={150000}
                                    max={2000000}
                                    thumbClassName="example-thumb"
                                    trackClassName="example-track"
                                    renderThumb={(props, state) => <div {...props}><span className="thumbnail"><NumericFormat displayType={'text'} thousandSeparator={true} value={state.valueNow} /></span></div>}
                                    value={sliderValue}
                                    onChange={slideChangeFunction}
                                />
                                <div className="slideMarks">
                                    <p>150K</p>
                                        <input type="text" value={sliderVal} onChange={handleSetSLiderValue} className="inputNumberType" />
                                        {/* <NumericFormat thousandSeparator={true} value={sliderValue} onChange={haldelValueChange} className="inputNumberType" inputmode="numeric" /> */}
                                    <p>2M</p>
                                </div>
                            </div>
                        </div>

                        <Link to="/investment-portfolio" className="bluebttnStyle">המשך לבחירת מנהל השקעות</Link>

                    </div>
                </div>
            </section>

            {
                riskLevelPop ?
                    <section className="riskPopWrap">
                        <div className="rPopMain">
                            <div className="removePop" onClick={closePop}><FontAwesomeIcon icon="fa-times" /></div>
                            <img src={popIcon} alt="" />
                            <h4>בחר את רמת הסיכון המועדפת עליך</h4>
                            <div className="rSlid">
                                <ReactSlider
                                    className="horizontal-slider"
                                    marks
                                    markClassName="example-mark"
                                    min={0}
                                    max={5}
                                    thumbClassName="example-thumb"
                                    trackClassName="example-track"
                                    renderThumb={(props) => <div {...props}><span className="thumbnail">{stepText}</span></div>}
                                    // value={2}
                                    onChange={changeFunction}
                                    ariaLabelledby={steps}   
                                    ariaLabel={steps}                 
                                />
                                <div className="arealabel">
                                    {Object.values(steps).map((step, key) => {
                                        return (
                                            <p key={key}>{step}</p>
                                        );
                                    })}
                                </div>
                            </div>
                            <button className="subBttn" onClick={closePop}>לאישור שינוי רמת הסיכון</button>
                        </div>
                    </section>
                :null
            }

        </>
    )
}
export default RiskLevel;