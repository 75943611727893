import logo from "../images/logo.svg";
import { Link } from "react-router-dom";
function Header(){
    return(
        <>
            <header>
                <Link className="logoWrap"><img src={logo} alt="" /></Link>
                <div className="rightBttns">
                    <Link className="btnStyle">שאלון השקעות</Link>
                    <Link className="linkStyle">יציאה</Link>
                </div>
            </header>            
        </>
    )
}

export default Header;