import { useState } from "react";
function Addform4(){
    const [idValue, setIdValue] = useState('');
    const [attachment, setAttachment] = useState('');
    const [idValidValue, setIdValidValue] = useState('');
    function handelIdImage(fileId){
        setIdValue(fileId.name)
    }
    function handelAttachmentImage(fileAttachment){
        setAttachment(fileAttachment.name);
    }
    function handelIdValidImage(validId){
        setIdValidValue(validId.name);
    }
    return(
        <>
            <section className="completeFormBlock">
                <div className="formInner">
                    <div className="countBar">
                        <button></button>
                        <button className="active"></button>
                        <button></button>
                        <button></button>
                    </div>
                    <h2>מסמכים וצרופות</h2>

                    <div className="mainForm">

                        <div className="uploadTypeFormFeald">
                            <label className="fealdType"><b>{idValue}</b>
                                <p className="fildLabel">ת.ז</p>
                                <input type="file" className="fType" onChange={(e)=>handelIdImage(e.target.files[0])} />
                            </label>
                            
                            <label className="fealdType"><b>{attachment}</b>
                                <p className="fildLabel">ספח</p>
                                <input type="file" className="fType" onChange={(e)=>handelAttachmentImage(e.target.files[0])} />
                            </label>
                            
                            <label className="fealdType"><b>{idValidValue}</b>
                                <p className="fildLabel">מסמך מזהה נוסף בתוקף</p>
                                <input type="file" className="fType" onChange={(e)=>handelIdValidImage(e.target.files[0])} />
                            </label>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}
export default Addform4;