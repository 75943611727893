import Header from "../components/header";
import Addform1 from "../components/Addform1";
import Addform2 from "../components/Addform2";
import Addform2a from "../components/Addform2a";
import Addform3 from "../components/Addform3";
import SubmitPage from "../components/SubmitPage";
import { useState } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from "react-router-dom";
function Addmamber(){
    library.add( faTimes );
    const [formOne, setFormOne] = useState('formTotalWrap show');
    const [formTwo, setFormTwo] = useState('formTotalWrap');
    const [formTwo_a, setFormTwo_a] = useState('formTotalWrap');
    const [formThree, setFormThree] = useState('formTotalWrap');
    const [submitPage, setSubmitPage] = useState('formTotalWrap');
    
    const [checkedRadios, setCheckedRadios] = useState(false);

    const [checkedRadios_a, setCheckedRadios_a] = useState(false);
    
    const [checked, setChecked] = useState(false);

    const [showTwo_a, setShowTwo_a] = useState(false);

    const [errorPopup, setErrorPopup] = useState(false);

    function goFormOne(){
        setFormTwo('formTotalWrap');
        setFormOne('formTotalWrap show');
        setFormThree('formTotalWrap');
        setSubmitPage('formTotalWrap');
        setFormTwo_a('formTotalWrap');
        window.scrollTo({
            top: 0, 
        });
    }

    function goFormTwo(){
        setFormOne('formTotalWrap');
        setFormTwo('formTotalWrap show');
        setFormThree('formTotalWrap');
        setSubmitPage('formTotalWrap');
        setFormTwo_a('formTotalWrap');
        window.scrollTo({
            top: 0, 
        });
    }

    function goFormTwo_a(){
        setFormOne('formTotalWrap');
        setFormTwo('formTotalWrap');
        setFormTwo_a('formTotalWrap show');
        setFormThree('formTotalWrap');
        setSubmitPage('formTotalWrap');
        window.scrollTo({
            top: 0, 
        });
    }

    function goFormThree(){
        setFormTwo('formTotalWrap');
        setFormThree('formTotalWrap show');
        setFormOne('formTotalWrap');
        setSubmitPage('formTotalWrap');
        setFormTwo_a('formTotalWrap');
        window.scrollTo({
            top: 0, 
        });
    }

    function goSubmitPage(){
        setFormTwo('formTotalWrap');
        setFormThree('formTotalWrap');
        setFormOne('formTotalWrap');
        setSubmitPage('formTotalWrap show');
        setFormTwo_a('formTotalWrap');
        window.scrollTo({
            top: 0, 
        });
    }

    // function submitMemberForm(){
    //     setErrorPopup(true)
    // }
    function closeSubPop(){
        setErrorPopup(false)
    }
    return(
        <>
            <Header></Header>
            
                <section className={formOne}>
                    <Addform1 setChecked={setChecked} setShowTwo_a = {setShowTwo_a}></Addform1>
                    <div className="subBttns">
                        {
                            checked ?
                            <button className="subBttn" onClick={goFormTwo}>המשך לשלב הבא</button>:
                            <button className="subBttn disabledClass" disabled>המשך לשלב הבא</button>
                        }
                        
                        <Link to='/investment-portfolio' className="backbttn">חזור</Link>
                    </div>
                </section>
            
                <section className={formTwo}>
                    <Addform2 setCheckedRadios={setCheckedRadios}></Addform2>
                    <div className="subBttns">
                        {checkedRadios ? 
                            <button className="subBttn" onClick={goFormThree} >המשך לשלב הבא</button>:
                            <button className="subBttn disabledClass">המשך לשלב הבא</button>
                        }
                        {checkedRadios && showTwo_a ? 
                            <button className="subBttn overlap" onClick={goFormTwo_a} >המשך לשלב הבא</button>:null
                        }
                        <button className="backbttn" onClick={goFormOne}>חזור</button>
                    </div>
                </section>
            
                <section className={formTwo_a}>
                    <Addform2a setCheckedRadios_a={setCheckedRadios_a}></Addform2a>
                    <div className="subBttns">
                        {checkedRadios_a ? 
                            <button className="subBttn" onClick={goFormThree} >המשך לשלב הבא</button>:
                            <button className="subBttn disabledClass">המשך לשלב הבא</button>
                        }
                        <button className="backbttn" onClick={goFormTwo}>חזור</button>
                    </div>
                </section>
            
                <section className={formThree}>
                    <Addform3></Addform3>
                    <div className="subBttns">
                        <button className="subBttn" onClick={goSubmitPage}>המשך לשלב הסיום</button>
                        { showTwo_a ? 
                            <button className="backbttn" onClick={goFormTwo_a}>חזור</button>:
                            <button className="backbttn" onClick={goFormTwo}>חזור</button>
                        }
                    </div>
                </section>
            
                <section className={submitPage}>
                    <SubmitPage></SubmitPage>
                    <div className="subBttns">
                        <button className="subBttn">סיום</button>
                        <button className="backbttn" onClick={goFormThree}>חזור</button>
                    </div>
                </section>

                {
                    errorPopup ?
                    <section className="ErrorPopUpWrap">
                        <div className="errorPopmain">
                            <span className="closePop" onClick={closeSubPop}><FontAwesomeIcon icon='fa-times' /></span>
                            <span className="erroricon"><FontAwesomeIcon icon='fa-times' /></span>
                            <h4>אופס! <br /> כותרת שגיאה מדוע</h4>
                            <p>הסבר מדוע לא ניתן להמשיך אפשר 2 שורות</p>
                            <button className="subBttn" onClick={closeSubPop}>חזרה לשינוי רמת סיכון</button>
                        </div>
                    </section>:null
                }
            
        </>
    )
}

export default Addmamber;