import './css/style.css';
import './css/responsive.css';
import { Route, Routes } from "react-router-dom";

import Dashboard from './pages/Dashboard';
import Risklevel from './pages/Risklevel';
import InvestmentPortfolio from './pages/InvestmentPortfolio';
import Addmamber from './pages/Addmember';
import Login from './pages/Login';
import Otp from './pages/Otp';
function App() {
  return (
    <div className="completeApp">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="*" element={<Login />} />
        <Route path="/enter-code" element={<Otp />} />
        <Route path="/welcome" element={<Dashboard />} />
        <Route path="/risk-level" element={<Risklevel />} />
        <Route path="/investment-portfolio" element={<InvestmentPortfolio />} />
        <Route path="/add-member" element={<Addmamber />} />
      </Routes>
    </div>
  );
}

export default App;
